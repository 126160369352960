import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import router from './router'
import { api } from "./services/api";
import createPersistedState from "vuex-persistedstate";
import localforage from 'localforage';
import LZString from 'lz-string';
//import * as Cookies from "js-cookie";

Vue.use(Vuex)
const getDefaultState = () => {
  return {
    debounceCode: '',
    debouncing: false,
    orderTypeId: '',
    orderingEnd: '',
    tableOccupyEnd: '',
    mobileNumber: '',
    orderRemark: '',
    customerName: '',
    locale: 'zh',
    landingMessageRead: false,
    en: {
      takeAway: 'Take Away',
      menu: 'Menu',
      orderHistory: 'Order History',
      contact: 'Contact Us',
      submitOrder: 'Submit Order',
      tableNumber: 'Table',
      optionTags: 'Custom Options',
      setTags: 'Custom Set',
      items: 'Items',
      soldOut: 'Sold out',
    },
    zh: {
      takeAway: '外賣自取',
      menu: '精選菜單',
      orderHistory: '落單紀錄',
      contact: '聯絡我們',
      submitOrder: '準備落單',
      tableNumber: '號枱',
      optionTags: '自選口味',
      setTags: '套餐選擇',
      items: '項目',
      soldOut: '售罄',
    },
    server: process.env.VUE_APP_SERVER_URL,
    imgServer: process.env.VUE_APP_IMG_SERVER_URL,
    modalVisible: false,
    modalTitle: '',
    modalContent: '',
    clientId: '',
    tableNumber: '',
    orderHistory: [],
    orderPOS: [],
    restaurantOrderHistory: [],
    hash: '',
    drawer: false,
    mode: '',
    takeAwayMenuTab: null,
    company: {
      name_en: '',
      name_zh: '',
    },
    shop: {
      id: '',
      name_en: '',
      name_zh: '',
      vertical: '',
      image: '',
      slug: '',
      address_line_1_en: '',
      address_line_2_en: '',
      address_line_3_en: '',
      address_line_1_zh: '',
      address_line_2_zh: '',
      address_line_3_zh: '',
      color_theme: '',
    },
    menu: {
      //service_charge_percentage:0,
    },
    currentSet: {},
    currentItem: {},
    addToCartItem: {},
    currentSetOptionItem: {},
    currentOptions: [],
    cart: [],
    cartItemTotal: 0,
    cartTotal: 0,
    soldOutProduct: [],
    soldOutOption: [],
    posOnlyProduct: [],
    open: true,
    paymentPending: false,
    orderTotal: 0,
    takeawayDiscountPercentage: 0,
    couponCode: '',
    couponDiscount: 0,
    couponError: '',
    message: '',
    coupons: [],
  }
}
const state = getDefaultState()


export const store = new Vuex.Store({
  plugins: [createPersistedState(
    {
      overwrite: true,
      storage: {

        getItem: (key) => {

          console.time('decompress_menu')
          let menu_str = LZString.decompressFromBase64(window.localStorage.getItem('menu'));
          console.log('menu_str: ', menu_str)

          let menu_obj = {}
          if (menu_str) {

            try {
              menu_obj = JSON.parse(menu_str)
            } catch (e) {

              window.localStorage.removeItem('menu')
              location.reload()
            }


          }
          console.timeEnd('decompress_menu')

          let value_str = ''
          value_str = window.localStorage.getItem(key);
          let value = {}
          if (value_str) {
            try {
              value = JSON.parse(value_str)
            } catch (e) {

            }
          } else {
            value = getDefaultState()
          }


          if (value) {
            value.menu = {}
            Object.assign(value.menu, menu_obj)
          }

          return JSON.stringify(value);

        },
        setItem: (key, value) => {

          let val = JSON.parse(value)
          delete val.menu
          let valstr = JSON.stringify(val)
          return window.localStorage.setItem(key, valstr)


        },
        removeItem: (key) => window.localStorage.removeItem(key),
      },
    }
  )],

  mutations: {
    setDebounceCode(state, code) {
      state.debounceCode = code
    },
    restoreState(state) {
      localforage.getItem('menu').then(function (value) {
        // This code runs once the value has been loaded
        // from the offline store.
        Object.assign(state.menu, JSON.parse(value))
        console.log('restore menu');
      }).catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

      localforage.getItem('shop').then(function (value) {
        // This code runs once the value has been loaded
        // from the offline store.
        Object.assign(state.shop, JSON.parse(value))
        console.log('restore shop');
      }).catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    },

    resetState(state) {
      console.log('resetState()')
      window.localStorage.removeItem('vuex')
      window.localStorage.removeItem('menu')
      Object.assign(state, getDefaultState())

    },
    setLocale(state, data) {
      state.locale = data;
    },
    cart_item_change(state, item) {

      var target = null;
      //if the element added before

      if (item.cartIndex >= 0) {
        target = item.cartIndex
      }

      // state.cart.forEach(function (element, index, item) {
      //   console.log(item)
      //   if (element.id == item.id && item.cart_index == index) {
      //     target = index
      //   }
      // })

      if (target == null) {
        //item not existing in cart yet
        new_item = JSON.parse(JSON.stringify(item))
        state.cart.push(new_item)

      } else {

        //same item added to cart before, just update the quantity
        state.cart[target].quantity += parseInt(item.quantity_change)
        var new_item = JSON.parse(JSON.stringify(state.cart[target]))

        if (new_item.quantity > 0) {
          //replace the item to triger reactive
          Vue.set(state.cart, target, new_item);

        } else {
          state.cart.splice(target, 1);

        }

      }



    },
    remove_item(state, index) {
      state.cart.splice(index, 1);
    },
    add_cart_item_qty(state, index) {
      state.cart[index].quantity += 1;
    },
    add_set(state, item) {

      if (!item.quantity) {
        item.quantity = 1;
      }


      var new_item = JSON.parse(JSON.stringify(item));
      console.log(new_item)
      if (new_item.cartIndex >= 0) {
        //it is an edit case
        state.cart.splice(new_item.cartIndex, 1, new_item);
      } else {
        //add a new 
        state.cart.push(new_item);
      }
      state.currentSet = {};

    },
    setDrawer(state, newValue) {
      state.drawer = newValue;
    },
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    },
    hideModal(state) {
      state.modalVisible = false;
    },
    showModal(state) {
      state.modalVisible = true;
    },
    hideModal(state) {
      state.modalVisible = false;
    },
    setModalTitle(state, value) {
      state.modalTitle = value;
    },
    setModalContent(state, value) {
      state.modalContent = value;
    },
    setModalButton(state, value) {
      state.modalButton = value;
    },
    setLandingMessageRead(state, value) {
      state.landingMessageRead = value;
    },
    setTableInfo(state, tableInfo) {
      //localStorage.clear();


      state.orderTypeId = 1;
      state.orderingEnd = tableInfo.orderingEnd;
      state.tableOccupyEnd = tableInfo.tableOccupyEnd;
      //console.log('tab info',tableInfo)

      state.company.id = tableInfo.company.id;
      state.company.name_en = tableInfo.company.name_en;
      state.company.name_zh = tableInfo.company.name_zh;


      state.shop.id = tableInfo.shop.id;
      state.shop.name_en = tableInfo.shop.name_en;
      state.shop.name_zh = tableInfo.shop.name_zh;
      state.shop.slug = tableInfo.shop.slug;
      state.shop.vertical = tableInfo.shop.vertical;
      state.shop.image = tableInfo.shop.image;

      state.shop.ga_code = tableInfo.shop.ga_code;
      state.shop.gtm_code = tableInfo.shop.gtm_code;
      state.shop.fbp_code = tableInfo.shop.fbp_code;

      state.shop.address_line_1_en = tableInfo.shop.address_line_1_en;
      state.shop.address_line_2_en = tableInfo.shop.address_line_2_en;
      state.shop.address_line_3_en = tableInfo.shop.address_line_3_en;

      state.shop.address_line_1_zh = tableInfo.shop.address_line_1_zh;
      state.shop.address_line_2_zh = tableInfo.shop.address_line_2_zh;
      state.shop.address_line_3_zh = tableInfo.shop.address_line_3_zh;

      state.shop.color_theme = tableInfo.shop.color_theme;
      state.restaurantSlug = tableInfo.shop.slug;
      state.tableNumber = tableInfo.tableNumber;
      //
    },
    setRestaurantInfo(state, RestaurantInfo) {
      state.shop = RestaurantInfo;
    },
    setMode(state, mode) {
      state.mode = mode;
    },
    setOrderType(state, id) {
      state.orderTypeId = id;
    },
    setHash(state, hash) {
      state.hash = hash;
    },
    setStaticHash(state, hash) {
      state.statichash = hash;
    },
    setRestaurantSlug(state, slug) {
      state.restaurantSlug = slug;
    },

    fetchLocalStorage(state) {

      // if(localStorage.orderTypeId>0){
      //   state.orderTypeId = localStorage.orderTypeId;

      //   state.shop = JSON.parse(localStorage.shop);
      //   state.mode = localStorage.mode;
      //   state.hash = localStorage.hash;


      //   if (state.orderTypeId == 1) {
      //     state.company = JSON.parse(localStorage.company);
      //     state.tableNumber = localStorage.tableNumber;
      //   }
      // }


    },
    setOrderHistory(state, history) {
      state.orderHistory = history;
    },
    setOrderPOS(state, data) {
      state.orderPOS = data;
    },
    setRestaurantOrderHistory(state, history) {
      state.restaurantOrderHistory = history;
    },
    removeItemByCode(state, code) {
      state.cart = state.cart.filter(subArray => subArray.code !== code);
      state.cartItemTotal = 0;
      state.cartTotal = 0;
    },
    emptyCart(state) {
      state.cart = [];
      state.cartItemTotal = 0;
      state.cartTotal = 0;
    },
    emptyCartAndMenu(state) {
      state.cart = [];

      for (var i = 0; i < state.menu.pages.length; i++) {
        for (var j = 0; j < state.menu.pages[i].items.length; j++) {
          state.menu.pages[i].items[j].quantity = 0;
        }
      }

      state.cartItemTotal = 0;
      state.cartTotal = 0;
    },
    setCurrentSet(state, item) {
      var new_item = JSON.parse(JSON.stringify(item));
      state.currentSet = new_item;
    },
    updateCurrentSet(state) {
      //must do all extra cost calculation in this fucntion, then the cart function can do later

      //this is the outer most extraCost
      state.currentSet.extraCost = 0;


      state.currentSet.setItems.forEach(element => {

        if (element.type == "item-group") {
          element.extraCost = 0;
          element.items.forEach(item => {
            if (element.selected == item.id) {

              if (item.type == 'options') {
                if (parseFloat(item.extraCost) > 0) {
                  element.extraCost = parseFloat(item.extraCost)
                  //state.currentSet.extraCost= parseFloat(state.currentSet.extraCost)+parseFloat(item.extraCost)
                  //console.log(item)
                  console.log('set item extra cost')
                }

                if (parseFloat(item.price) > 0) {
                  element.extraCost = element.extraCost + parseFloat(item.price)
                }

                console.log('set Set Item extraCost (options): ' + parseFloat(item.extraCost))
              } else {
                element.extraCost = parseFloat(item.price)
                //state.currentSet.extraCost= parseFloat(state.currentSet.extraCost)+parseFloat(item.price);
                console.log('set Set Item extraCost (item-group): ' + parseFloat(item.price))
              }


            }
          });

          state.currentSet.extraCost = parseFloat(state.currentSet.extraCost) + parseFloat(element.extraCost);

        } else if (element.type == "option-group") {
          element.extraCost = 0;

          element.items.forEach(optionGroup => {

            if (optionGroup.option_group_type_id == 1 || optionGroup.option_group_type_id == 4) {
              optionGroup.items.forEach(option => {

                if (optionGroup.selected == option.id && parseInt(option.price) > 0) {
                  console.log(option.name_en);

                  element.extraCost = element.extraCost + parseInt(option.price);
                  console.log('set Set Item extraCost (option-group): ' + parseFloat(element.extraCost))
                  state.currentSet.extraCost = state.currentSet.extraCost + parseInt(option.price);
                }
              });

            }
            if (optionGroup.option_group_type_id == 2) {
              optionGroup.items.forEach(option => {

                if (option.selected && parseInt(option.price) > 0) {

                  element.extraCost = element.extraCost + parseInt(option.price);
                  console.log('set Set Item extraCost (option-group): ' + parseFloat(element.extraCost))
                  state.currentSet.extraCost = state.currentSet.extraCost + parseInt(option.price);
                } else if (option.quantity > 0) {
                  element.extraCost = element.extraCost + parseInt(option.price) * parseInt(option.quantity);
                  console.log('set Set Item extraCost (option-group add-on qty): ' + parseFloat(element.extraCost))
                  state.currentSet.extraCost = state.currentSet.extraCost + parseInt(option.price) * parseInt(option.quantity);
                }
              });
            }


          });

          // state.currentSet.extraCost= parseInt(state.currentSet.extraCost)+ parseInt(element.extraCost) ;
        }

      });

      console.log(state.currentSet.extraCost);
    },

    setCurrentItem(state, item) {
      var new_item = JSON.parse(JSON.stringify(item));
      state.currentItem = new_item;
    },
    setAddToCartItem(state, item) {
      var new_item = JSON.parse(JSON.stringify(item));
      state.addToCartItem = new_item;
    },
    clearCurrentItem(state) {
      state.currentItem = {};
    },
    setCuttentSetOptionItem(state, item) {
      state.currentSetOptionItem = item;
    },
    setCurrentOptions(state, item) {
      state.currentOptions = item;
    },
    setTakeAwayMenuTab(state, tab) {
      state.takeAwayMenuTab = tab;
    },
    jumpHistory() {
      router.push({
        path: '/orderHistory'
      });
    },

    jumpTakeAwayMenu() {
      router.push({
        path: '/TakeAwayMenu'
      });
    },

    updateCartTotal(state) {

      function optionExtraCost(item) {
        var extraCost = 0;
        if (item.optionGroups.length > 0) {
          for (var i = 0; i < item.optionGroups.length; i++) {
            if (item.optionGroups[i].extraCost > 0) {
              extraCost += parseInt(item.optionGroups[i].extraCost);
            }
          }
        }
        return extraCost;
      }

      function addOnExtraCost(item) {
        var extraCost = 0;
        if (item.addOnGroups.length > 0) {
          for (var i = 0; i < item.addOnGroups.length; i++) {
            for (var j = 0; j < item.addOnGroups[i].addOns.length; j++) {
              if (typeof item.addOnGroups[i].addOns[j].selected !== 'undefined' && item.addOnGroups[i].addOns[j].selected) {
                if (item.addOnGroups[i].addOns[j].price > 0) {

                  extraCost += parseInt(item.addOnGroups[i].addOns[j].price)
                }
              } else if (item.addOnGroups[i].addOns[j].quantity > 0) {
                if (item.addOnGroups[i].addOns[j].price > 0) {

                  extraCost += parseInt(item.addOnGroups[i].addOns[j].price * item.addOnGroups[i].addOns[j].quantity)
                }
              }
            }
          }
        }


        return extraCost;
      }

      function choiceExtraCost(item) {
        var extraCost = 0;
        if (item.hasOwnProperty('choiceGroups') && item.choiceGroups.length > 0) {
          for (var i = 0; i < item.choiceGroups.length; i++) {
            for (var j = 0; j < item.choiceGroups[i].choices.length; j++) {
              extraCost += item.choiceGroups[i].choices[j].quantity * item.choiceGroups[i].choices[j].price
            }
          }
        }
        return extraCost;
      }




      state.cartItemTotal = 0;

      state.cart.forEach(function (item) {
        if (item.type == 'set') {
          //assuming the calsulation is ready
          state.cartItemTotal = state.cartItemTotal + (item.price * item.quantity) + (item.extraCost * item.quantity);
          // item.setItems.forEach(function (setItem) {
          //   if (setItem.type == 'option-group') {
          //     setItem.items.forEach(function (option) {
          //       state.cartItemTotal = state.cartItemTotal + parseInt(option.extraCost);
          //     });
          //   } else {
          //     state.cartItemTotal = state.cartItemTotal + parseInt(setItem.extraCost);
          //   }
          // });

        } else if (item.type == 'options') {


          var extraCost = 0;
          extraCost += optionExtraCost(item);
          extraCost += addOnExtraCost(item);
          extraCost += choiceExtraCost(item);

          //alert(extraCost);
          state.cartItemTotal = state.cartItemTotal + ((parseInt(item.price) + parseInt(extraCost)) * item.quantity);

        } else {
          state.cartItemTotal = state.cartItemTotal + (item.price * item.quantity);
        }

      });

      // Calculate base total without any discounts
      let baseTotal = parseFloat(state.cartItemTotal) || 0;
      console.log('Initial total:', baseTotal);

      // Sort and separate coupons
      const preServiceChargeCoupons = state.coupons
        .filter(coupon => coupon.applyBeforeServiceCharge)
        .sort((a, b) => b.priority - a.priority);

      const postServiceChargeCoupons = state.coupons
        .filter(coupon => !coupon.applyBeforeServiceCharge)
        .sort((a, b) => b.priority - a.priority);

      // Apply pre-service charge coupons
      let total = baseTotal;
      preServiceChargeCoupons.forEach(coupon => {
        if (coupon.type === 'percentage') {
          const discount = total * (coupon.value / 100);
          coupon.discount = discount;
          total -= discount;
        } else {
          coupon.discount = Math.min(total, coupon.value);
          total -= coupon.discount;
        }
      });

      // Apply service charge if applicable
      if (state.serviceChargePercentage > 0) {
        total += total * (state.serviceChargePercentage / 100);
      }

      // Apply post-service charge coupons
      postServiceChargeCoupons.forEach(coupon => {
        if (coupon.type === 'percentage') {
          const discount = total * (coupon.value / 100);
          coupon.discount = discount;
          total -= discount;
        } else {
          coupon.discount = Math.min(total, coupon.value);
          total -= coupon.discount;
        }
      });

      // Apply existing takeaway discount if applicable
      if (state.takeawayDiscountPercentage > 0) {
        const takeawayDiscount = total * (state.takeawayDiscountPercentage / 100);
        total -= takeawayDiscount;
      }

      console.log('Final total:', total);
      state.cartTotal = Math.max(0, total);
      state.cartTotal =999;

    },
    setRestaurantMenu(state, data) {
      //console.log('compressed_menu_data: ',data)
      console.time('decompress_menu_and_parse_json')
      var menu = JSON.parse(LZString.decompressFromBase64(data))
      console.timeEnd('decompress_menu_and_parse_json')

      Object.assign(state.menu, menu)

      // let str = JSON.stringify(data);
      // console.time('compress_menu')
      // str= LZString.compress(str);
      // console.timeEnd('compress_menu')
      window.localStorage.setItem('menu', data)

      // localforage.setItem('menu', JSON.stringify(data)).then(function(){
      //   console.log('store menu')
      // });

      // localforage.setItem('shop', JSON.stringify(state.shop)).then(function(){
      //   console.log('store shop')
      // });
      state.menu = menu
    },
    setMobileNumber(state, data) {
      state.mobileNumber = data
    },
    setOrderRemark(state, data) {
      state.orderRemark = data
    },

    setCustomerName(state, data) {
      state.customerName = data
    },

    syncMenuQuabtity(state, id) {

      var quantity = 0;
      for (var k = 0; k < state.cart.length; k++) {
        quantity = state.cart[k].quantity;
      }


      for (var i = 0; i < state.menu.pages.length; i++) {
        for (var j = 0; j < state.menu.pages[i].items.length; j++) {
          if (state.menu.pages[i].items[j].id == id) {
            state.menu.pages[i].items[j].quantity = quantity;
          }
        }
      }
    },
    setSoldOutProduct(state, data) {

      state.soldOutProduct = data;
    },
    setSoldOutOption(state, data) {

      state.soldOutOption = data;
    },
    setPosOnlyProduct(state, data) {
      state.posOnlyProduct = data;
    },
    setServiceChargePercentage(state, data) {
      state.serviceChargePercentage = data;
    },
    setTakeawayDiscountPercentage(state, data) {
      state.takeawayDiscountPercentage = data;
    },
    setPaymentPending(state, data) {
      state.paymentPending = data;
    },
    setOrderTotal(state, data) {
      state.orderTotal = data;
    },
    setRestaurantList(state, data) {
      state.restaurantList = data;
    },
    initPixel(state, data) {
      if (state.shop.fbp_code) {
        var img = document.createElement("img");
        img.src = "https://www.facebook.com/tr?id=" + state.shop.fbp_code + "&ev=PageView&noscript=1";
        img.width = 1;
        img.height = 1;
        img.cssText = 'display:none';
        document.body.appendChild(img);
        fbq('init', state.shop.fbp_code);
        fbq('track', 'PageView');
        fbq('track', 'MenuHome');
      }
    },
    initGA() {
      if (state.shop.ga_code) {
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', ga_code);
      }
    },
    setCouponCode(state, code) {
      state.couponCode = code;
    },
    setCouponDiscount(state, amount) {
      state.couponDiscount = amount;
    },
    setCouponError(state, error) {
      state.couponError = error;
    },
    clearCoupon(state) {
      state.couponCode = '';
      state.couponDiscount = 0;
      state.couponError = '';
    },
    setCouponId(state, id) {
      state.couponId = id;
    },

    setCouponType(state, type) {
      state.couponType = type;
    },

    setCouponValue(state, value) {
      state.couponValue = value;
    },

    setCouponError(state, error) {
      state.couponError = error;
    },

    resetCoupon(state) {
      state.couponCode = '';
      state.couponId = null;
      state.couponType = '';
      state.couponValue = 0;
      state.couponError = '';
      state.couponDiscount = 0;
    },
    setMessage(state, message) {
      state.message = message;
    },
    addCoupon(state, coupon) {
      state.coupons.push({
        code: coupon.code,
        id: coupon.coupon_id,
        type: coupon.discount_type,
        value: coupon.discount_value,
        applyBeforeServiceCharge: coupon.apply_before_service_charge,
        priority: coupon.priority || 0,
        discount: 0
      });

      // Sort coupons by priority (higher priority first)
      state.coupons.sort((a, b) => b.priority - a.priority);
    },

    removeCouponByCode(state, code) {
      state.coupons = state.coupons.filter(coupon => coupon.code !== code);
    },

    resetCoupons(state) {
      state.coupons = [];
    },

    updateCartTotal(state) {
      function optionExtraCost(item) {
        var extraCost = 0;
        if (item.optionGroups.length > 0) {
          for (var i = 0; i < item.optionGroups.length; i++) {
            if (item.optionGroups[i].extraCost > 0) {
              extraCost += parseInt(item.optionGroups[i].extraCost);
            }
          }
        }
        return extraCost;
      }

      function addOnExtraCost(item) {
        var extraCost = 0;
        if (item.addOnGroups.length > 0) {
          for (var i = 0; i < item.addOnGroups.length; i++) {
            for (var j = 0; j < item.addOnGroups[i].addOns.length; j++) {
              if (typeof item.addOnGroups[i].addOns[j].selected !== 'undefined' && item.addOnGroups[i].addOns[j].selected) {
                if (item.addOnGroups[i].addOns[j].price > 0) {

                  extraCost += parseInt(item.addOnGroups[i].addOns[j].price)
                }
              } else if (item.addOnGroups[i].addOns[j].quantity > 0) {
                if (item.addOnGroups[i].addOns[j].price > 0) {

                  extraCost += parseInt(item.addOnGroups[i].addOns[j].price * item.addOnGroups[i].addOns[j].quantity)
                }
              }
            }
          }
        }


        return extraCost;
      }

      function choiceExtraCost(item) {
        var extraCost = 0;
        if (item.hasOwnProperty('choiceGroups') && item.choiceGroups.length > 0) {
          for (var i = 0; i < item.choiceGroups.length; i++) {
            for (var j = 0; j < item.choiceGroups[i].choices.length; j++) {
              extraCost += item.choiceGroups[i].choices[j].quantity * item.choiceGroups[i].choices[j].price
            }
          }
        }
        return extraCost;
      }




      state.cartItemTotal = 0;

      state.cart.forEach(function (item) {
        if (item.type == 'set') {
          //assuming the calsulation is ready
          state.cartItemTotal = state.cartItemTotal + (item.price * item.quantity) + (item.extraCost * item.quantity);
          // item.setItems.forEach(function (setItem) {
          //   if (setItem.type == 'option-group') {
          //     setItem.items.forEach(function (option) {
          //       state.cartItemTotal = state.cartItemTotal + parseInt(option.extraCost);
          //     });
          //   } else {
          //     state.cartItemTotal = state.cartItemTotal + parseInt(setItem.extraCost);
          //   }
          // });

        } else if (item.type == 'options') {


          var extraCost = 0;
          extraCost += optionExtraCost(item);
          extraCost += addOnExtraCost(item);
          extraCost += choiceExtraCost(item);

          //alert(extraCost);
          state.cartItemTotal = state.cartItemTotal + ((parseInt(item.price) + parseInt(extraCost)) * item.quantity);

        } else {
          state.cartItemTotal = state.cartItemTotal + (item.price * item.quantity);
        }

      });

      // Calculate base total
      let baseTotal = state.cartItemTotal;
      let preServiceChargeTotal = baseTotal;
      let finalTotal = baseTotal;

      // Sort and separate coupons
      const preServiceChargeCoupons = state.coupons
        .filter(coupon => coupon.applyBeforeServiceCharge)
        .sort((a, b) => b.priority - a.priority);

      const postServiceChargeCoupons = state.coupons
        .filter(coupon => !coupon.applyBeforeServiceCharge)
        .sort((a, b) => b.priority - a.priority);

      // Apply pre-service charge coupons
      preServiceChargeCoupons.forEach(coupon => {
        if (coupon.type === 'percentage') {
          const discount = preServiceChargeTotal * (coupon.value / 100);
          coupon.discount = discount;
          preServiceChargeTotal -= discount;
        } else {
          coupon.discount = Math.min(preServiceChargeTotal, coupon.value);
          preServiceChargeTotal -= coupon.discount;
        }
      });

      // Apply service charge
      let serviceChargeAmount = 0;
      if (state.serviceChargePercentage > 0) {
        serviceChargeAmount = preServiceChargeTotal * (state.serviceChargePercentage / 100);
        finalTotal = preServiceChargeTotal + serviceChargeAmount;
      } else {
        finalTotal = preServiceChargeTotal;
      }

      // Apply post-service charge coupons
      postServiceChargeCoupons.forEach(coupon => {
        if (coupon.type === 'percentage') {
          const discount = finalTotal * (coupon.value / 100);
          coupon.discount = discount;
          finalTotal -= discount;
        } else {
          coupon.discount = Math.min(finalTotal, coupon.value);
          finalTotal -= coupon.discount;
        }
      });

      state.cartTotal = Math.max(0, finalTotal);

    },
  },
  actions: {
    add_item(context, item) {
      //for dim sum use only
      item.quantity_change = item.quantity
      context.commit('cart_item_change', item);
      context.commit('updateCartTotal');

    },
    sub_item(context, item) {
      item.quantity_change = -1
      context.commit('cart_item_change', item);
      context.commit('updateCartTotal');

    },
    remove_item(context, index) {
      context.commit('remove_item', index);
      context.commit('updateCartTotal');
      console.log('updateCartTotal after remove_item');
    },
    add_item_to_cart(context, item) {
      item.quantity_change = item.quantity;
      context.commit('cart_item_change', item);
      context.commit('updateCartTotal');
    },
    add_cart_item_qty(context, index) {
      context.commit('add_cart_item_qty', index);
      context.commit('updateCartTotal');
    },
    add_set_item(context, item) {
      context.commit('add_set', item);
    },
    addCurrentItemToCart({
      commit,
      state
    }) {
      commit('add_set', state.currentItem);
      commit('updateCartTotal');
    },
    submitCart({
      commit,
      state
    }) {
      if (state.debouncing) {
        console.log('debounced')
        return false;
      } else {
        state.debouncing = true;

        if (state.cart.length == 0) {
          return false;
        }

        api.post('/api/addOrderBatch', {
          company_id: state.company.id,
          shop_id: state.shop.id,
          menu_id: state.menu.id,
          cart: state.cart,
          hash: state.hash,
          order_type_id: state.orderTypeId,
          mobile_number: state.mobileNumber,
          customer_name: state.customerName,
          order_remark: state.orderRemark,
          debounceCode: state.debounceCode,
          // Update coupon information to support multiple coupons
          coupons: state.coupons.map(coupon => ({
            code: coupon.code,
            id: coupon.id,
            type: coupon.type,
            value: coupon.value,
            discount: coupon.discount,
            applyBeforeServiceCharge: coupon.applyBeforeServiceCharge,
            priority: coupon.priority
          }))
        }).then(response => {
          state.debouncing = false;
          if (response.data.result == 'success') {
            commit('setDebounceCode', response.data.data.debounceCode);

            commit('setModalTitle', '你的點餐已送出');
            commit('setModalContent', '請耐心等候。');
            commit('setModalButton', 'OK');
            commit('showModal', state);
            commit('emptyCart', state);
            if (response.data.data.checkoutUrl) {
              console.log('jumping gateway')
              window.location.href = response.data.data.checkoutUrl;
            }
            commit('jumpHistory', state);
          } else if (response.data.result == 'fail' && response.data.message == 'order checked out') {
            router.push('/table/' + state.hash)
            commit('setModalTitle', '歡迎光臨');
            commit('setModalContent', '訂單已結帳,希望可以再為你服務。');
            commit('setModalButton', 'none');
            commit('showModal', 'none');

          } else if (response.data.result == 'fail' && response.data.message == 'debounce failed') {
            console.log('debounce code failed.')
            commit('setDebounceCode', response.data.data.debounceCode);
            commit('setModalTitle', '剛才發生網路連接問題');
            commit('setModalContent', '請向服務員確認閣下之點餐。');
            commit('setModalButton', 'OK');
            commit('showModal', state);
            commit('emptyCart', state);
            commit('jumpHistory', state);
          } else if (response.data.result == 'fail' && response.data.message == 'item removed') {

            commit('setModalTitle', '唔好意思');
            commit('setModalContent', response.data.data.removed_item + '已售罄，請重新選擇。');
            commit('setModalButton', 'OK');
            commit('showModal', state);

            commit('removeItemByCode', response.data.data.removed_code);

            commit('updateCartTotal');
            // commit('emptyCart', state);
            // commit('jumpHistory', state);
          } else if (response.data.result == 'fail' && response.data.message == 'sync lock') {

            commit('setModalTitle', '餐牌正在更新中');
            commit('setModalContent', '請稍後片刻。');
            commit('setModalButton', 'OK');
            commit('showModal', state);

            //commit('removeItemByCode', response.data.data.removed_code); 

            //commit('updateCartTotal');
            // commit('emptyCart', state);
            // commit('jumpHistory', state);
          }
        })
          .catch(e => {
            state.debouncing = false;
            //console.log(e)
            //this.errors.push(e)
          })
      }
    },
    getTableByHash({
      commit,
      state
    }) {

      api.post('/api/getTableByHash', {
        hash: state.hash,
      }).then(response => {
        if (response.data.result == 'success') {

          commit('setTableInfo', response.data.tableInfo);
          commit('setMode', 'normal');
          commit('hideModal');

          if (response.data.paymentPending == true) {
            commit('setOrderTotal', response.data.orderTotal);
            commit('setPaymentPending', response.data.paymentPending);
            commit('setOrderTotal', response.data.orderTotal);
            router.push({
              path: '/orderHistory'
            });
          }
          commit('initPixel');
          commit('initGA');


        } else if (response.data.result == 'fail' && response.data.message == 'order checked out') {

          commit('setModalTitle', '歡迎光臨');
          commit('setModalContent', '訂單已結帳,希望可以再為你服務。');
          commit('setModalButton', 'none');
          commit('showModal');
        }
      })
        .catch(e => {
          this.errors.push(e)
        })
      //Vue.$forceUpdate(); 
    },
    getTableByStaticHash({
      commit,
      state
    }) {

      api.post('/api/getTableByStaticHash', {
        hash: state.statichash,
      }).then(response => {
        if (response.data.result == 'success') {

          commit('setTableInfo', response.data.tableInfo);
          commit('setMode', 'normal');
          commit('hideModal');
          commit('setHash', response.data.orderHash);
          if (response.data.paymentPending == true) {

            commit('setOrderTotal', response.data.orderTotal);
            commit('setPaymentPending', response.data.paymentPending);
            commit('setOrderTotal', response.data.orderTotal);
            router.push({
              path: '/orderHistory'
            });
          }
          commit('initPixel');
          commit('initGA');


        } else if (response.data.result == 'fail' && response.data.message == 'order checked out') {

          commit('setModalTitle', '歡迎光臨');
          commit('setModalContent', '訂單已結帳,希望可以再為你服務。');
          commit('setModalButton', 'none');
          commit('showModal');
        }
      })
        .catch(e => {
          this.errors.push(e)
        })
      //Vue.$forceUpdate(); 
    },
    getRestaurantMenu({
      commit,
      state
    }) {
      // this is a netural method now, do not set order type
      api.post(process.env.VUE_APP_SERVER_URL + 'api/getRestaurantMenu', {
        shop_slug: state.restaurantSlug,
        menu_type_id: state.orderTypeId,
        hash: state.hash,
      }).then(response => {
        if (response.data.open == false) {
          alert('QR Menu not available.');
          commit('setModalTitle', '歡迎光臨');
          commit('setModalContent', '現在已非營業時間,希望可以再為你服務。');
          commit('setModalButton', 'none');
          commit('showModal');
          router.push({
            path: '/',
          });

          return 0;
        }
        commit('setRestaurantInfo', response.data.shop);
        commit('setSoldOutProduct', response.data.soldOutProduct);
        commit('setSoldOutOption', response.data.soldOutOption);
        commit('setPosOnlyProduct', response.data.posOnlyProduct);
        commit('setServiceChargePercentage', response.data.service_charge_percentage);
        commit('setTakeawayDiscountPercentage', response.data.takeaway_discount);
        commit('setRestaurantMenu', response.data.menu_data_compressed);
        commit('setDebounceCode', response.data.debounceCode);
        //location.reload();
      })
        .catch(e => {
          console.log(e)
          this.errors.push(e)
        })
    },
    getOrderHistory({
      commit,
      state
    }) {


      if (state.orderTypeId == 1) {
        api.post(process.env.VUE_APP_SERVER_URL + 'api/orderHistory', {
          order_type_id: 1,
          hash: state.hash,
        }).then(response => {
          commit('setOrderHistory', response.data);
        })
          .catch(e => {
            //this.errors.push(e)
          })
      } else if (state.orderTypeId == 2) {
        axios.post(process.env.VUE_APP_SERVER_URL + 'api/orderHistory', {
          order_type_id: 2,
          hash: state.hash,

        }).then(response => {
          commit('setOrderHistory', response.data);

        })
          .catch(e => {
            //this.errors.push(e)
          })

        axios.post(process.env.VUE_APP_SERVER_URL + 'api/orderPOS', {
          hash: state.hash,
        }).then(response => {
          commit('setOrderPOS', response.data);
        })
          .catch(e => {
            //this.errors.push(e)
          })
      }

      api.post(process.env.VUE_APP_SERVER_URL + 'api/orderDetail', {
        hash: state.hash,
      }).then(response => {

        if (response.data.order_remark) {
          commit('setModalTitle', '請注意');
          commit('setModalContent', response.data.order_remark);
          commit('setModalButton', 'OK');
          commit('showModal', state);
        }

      })
        .catch(e => {
          //this.errors.push(e)
        })



    },
    getSoldOutProduct({
      commit,
      state
    }) {
      axios.post(process.env.VUE_APP_SERVER_URL + 'api/getSoldOutProduct', {
        shop_id: state.shop.id,
      }).then(response => {
        commit('setSoldOutProduct', response.data);
      })
        .catch(e => {
          //this.errors.push(e)
        })
    },
    getSoldOutOption({
      commit,
      state
    }) {
      axios.post(process.env.VUE_APP_SERVER_URL + 'api/getSoldOutOption', {
        shop_id: state.shop.id,
      }).then(response => {
        commit('setSoldOutOption', response.data);
      })
        .catch(e => {
          //this.errors.push(e)
        })
    },
    getPosOnlyProuct({
      commit,
      state
    }) {
      axios.post(process.env.VUE_APP_SERVER_URL + 'api/getPosOnlyProuct', {
        shop_id: state.shop.id,
      }).then(response => {
        commit('setPosOnlyProuct', response.data);
      })
        .catch(e => {
          //this.errors.push(e)
        })
    },

    getRestaurant({
      commit,
      state
    }) {
      api.post(process.env.VUE_APP_SERVER_URL + 'api/getRestaurant', {
        restaurantSlug: state.restaurantSlug,
      }).then(response => {
        commit('setRestaurantInfo', response.data);
        commit('setMode', 'takeAway');
        router.push({
          path: '/TakeAwayMenu'
        });
      })
        .catch(e => {
          this.errors.push(e)
        })
    },
    getRestaurantOrderHistory({
      commit,
      state
    }) {
      api.post(process.env.VUE_APP_SERVER_URL + 'api/orderHistory', {
        hash: state.hash,
      }).then(response => {
        commit('setRestaurantOrderHistory', response.data);
      })
        .catch(e => {
          this.errors.push(e)
        })
    },

    getRestaurantList({
      commit,
      state
    }) {
      api.post(process.env.VUE_APP_SERVER_URL + 'api/getRestaurantList', {
        hash: '',
      }).then(response => {
        commit('setRestaurantList', response.data);
      })
        .catch(e => {
          this.errors.push(e)
        })
    },
    async applyCoupon({ commit, state }, code) {
      try {
        // Check if coupon already applied
        if (state.coupons.some(c => c.code === code)) {
          commit('setCouponError', '此優惠券已使用');
          return;
        }

        const response = await api.post(process.env.VUE_APP_SERVER_URL + 'api/applyCoupon', {
          code: code,
          shop_id: state.shop.id,
          cart_total: state.cartItemTotal,
          applied_coupons: state.coupons.map(c => c.code)
        });

        if (response.data.success) {
          commit('addCoupon', {
            code: code,
            coupon_id: response.data.coupon_id,
            discount_type: response.data.discount_type,
            discount_value: response.data.discount_value,
            apply_before_service_charge: response.data.apply_before_service_charge,
            priority: response.data.apply_priority


          });
          commit('setCouponError', '');
          commit('updateCartTotal');
          commit('setMessage', response.data.message || '優惠券使用成功');
        } else {
          commit('setCouponError', response.data.message || '無效的優惠券');
        }
      } catch (error) {
        console.error('Coupon application error:', error);
        commit('setCouponError', '優惠券使用錯誤');
      }
    }

  }


})